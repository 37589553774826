import request from '../common/request';
import { tenantId, appKey } from '../common/config';

export function login(code) {
  return request({
    url: `/api/blade-auth/oauth/token?code=${code}&appKey=${appKey}&grant_type=wxoffiaccount&tenantId=${tenantId}`,
    method: 'POST',
  })
}

export function updateUserTel(data) {
  return request({
    url: '/api/blade-user/update-by-encrypt',
    method: 'POST',
    data
  })
}

export function updateUserInfo(data) {
  return request({
    url: '/api/blade-user/front/update-userinfo',
    method: 'POST',
    data
  })
}

export function getSms(data) {
  return request({
    url: '/api/blade-resource/sms/endpoint/send-validate',
    method: 'POST',
    data
  })
}

export function saveByMobile(data) {
  return request({
    url: '/api/blade-user/front/save-by-mobile',
    method: 'POST',
    data
  })
}

export const refreshToken = (refreshToken) => request({
  url: `/api/blade-auth/oauth/token?refresh_token=${refreshToken}&grant_type=refresh_token&tenantId=${tenantId}&scope=all`,
  method: 'POST',
});

export const getUserInfoByPhone = (username,phone) => request({
  url: `/api/blade-auth/oauth/token?tenantId=${tenantId}&username=${username}&phone=${phone}&grant_type=phone`,
  method: 'POST',
});



